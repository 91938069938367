import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetupService } from 'src/app/services/setup.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  @ViewChild('customerForm') public any: NgForm;
  loc: any[] = []; merchants: any[] = []; cust: any; user: any; type: string; id: string; edit: boolean; res: any; status = false;
  constructor(private api: ApiService, private _route: ActivatedRoute, private _nav: Router,
    private _set: SetupService, private _api: ManagerService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.getAll();
    this.formReset();
    if(this._use.active.typ ==="Admin" || this._use.active.typ === "Support") {
      this.getMerchant();
    }
  }

  getAll() {
    this.type = this._route.snapshot.paramMap.get('type') || 'Savings';
    this.id = this._route.snapshot.paramMap.get('id');
    this.getCust(this.id); // console.log(this.id);
    this.api.get('location?limit=100').subscribe((data: any) => {
      this.loc = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List');
    });
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getCust(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this._api.getCustomer(id).subscribe((data: any) => {
        this.cust = data;
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Customer Detail Fail', 'Unable to Fetch Selected Customer Edit for edit');
     });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  fileUpload(event) {
    // console.log(event);
    // this.img = event.target.files[0];
    this._api.Upload(event.target.files[0], 'Customer').subscribe((data: any) => {
      this.cust.image = data; console.log(data);
    });
  }

  postCustomer() {
    this._api.postCustomer(this.cust).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this._use.showMsg('success', 'Customer Added', 'Added to list Successfully');
      this._nav.navigate(['/customer']);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Customer');
    });
  }

  putCustomer() {
    // this.cust.userid = this._use.active.id; this.cust.date = this._use.date;
    this.cust.muserid = this._use.active.id; this.cust.mdate = this._use.date;
    this._api.putCustomer(this.cust.customerid, this.cust).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this._use.showMsg('success', 'Customer Updated', 'Details Updated Successfully');
      this._nav.navigate(['/customer']);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.cust = { type: this.type, code: null, status: 'Active',
        gender: '', fullname: '', mobile: '', locationid: '', pin: '',
        maritalstatus: '', dateofbirth: null, image: '', address: '', signature: '', active: true,
        placeofbirth: '', idtype: '', idnumber: '', expirydate: '',
        beneficaryname: '', beneficiaryrelationship: '', beneficiarycontact: '', 
        emergencycontactname: '', emergencycontactrelationship: '', emergencycontactmobile: '',
        residentalstatus: '', digitaladdress: '', employmentstatus: '', occupation: '', 
        totalmonthlyincomerange: '', employer: '', natureofbusiness: '', businesscontact: '',
        objective: '', risktolerance: '', investmenthorizon: '', investmentknowledge: '',
        sourceoffunds: '', nameofbank: '', branch: '', accountname: '', accountnumber: '', 
        accountholderauthorization: '', authorizationdate: '',
        merchantid: this._use.active.merchantid,
        userid: this._use.active.id, date: this._use.date, muserid: null, mdate: null
      };
    }
  }

  back() {
    this.formReset(this.any);
    this._nav.navigate(['/customer']);
  }

}
