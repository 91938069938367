import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, EmailValidator } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { TellerService } from 'src/app/services/teller.service';
import { SigninService } from 'src/app/services/signin.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.css']
})
export class SetupUserComponent implements OnInit {
  @ViewChild('userForm') public any: NgForm; sel: any;
  edit: boolean; list: boolean; usr: any; users: any[]; merchants: any[]; 
  res: any; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService,  private _setup: SetupService, private _api: SigninService, private _emp: TellerService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchants();
    }
    this.getAllUsers();
  }

  getMerchants(page=1,limit=100) {
    this.api.get('merchant?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Employee List');
    });
  }

  getAllUsers() {
    this.api.get('auth/users').subscribe((data: any) => {
      this.users = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'User Lists', 'Unable to Get All Users');
    });
  }
  
  getMore(page,limit) {
    this.api.get('auth/users?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.users = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  editUser(data) {
    // this.usr = this.users.find(s => s.id === data);
    this.usr = data;
    this.edit = true; this.list = true;
  }

  delUser(dat) {
    this._setup.delUser(dat.id).subscribe((data: any) => {
      this.res = data; this.getAllUsers();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete User'); });
  }

  reset(dat) {
    this.api.post('auth/Reset/'+dat.username, dat).subscribe((data: any) => {
      this.res = data;
      this.use.showMsg('success', 'User Account Reset', 'User Shld Check Email for his new password');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Reset Fail', 'Unable to Reset User');
    });
  }

  postUser() {
    if (this.usr.password === this.usr.conpassword) {
      this._setup.postUser(this.usr).subscribe((data: any) => {
        this.res = data; this.getAllUsers();
        this.formReset(this.any); this.list = false;
        this.use.showMsg('success', 'User Added', 'Added to list Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Adding Fail', 'Unable to Add New User');
      });
    } else {
      this.use.showMsg('error', 'Adding Fail', 'User password do not match');
    }
  }

  putUser() {
    this.usr.userid = this.use.active.id; this.usr.date = new Date();
    this.usr.muserid = this.use.active.id; this.usr.mdate = new Date();
    this._setup.putUser(this.usr.id, this.usr).subscribe((data: any) => {
      this.res = data; this.getAllUsers();
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'User Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.usr = {
        fullname: '', mobile: '', email: '',  username: '', password: '', conpassword: '', usertype: '', isAdmin: false, isSupper: false, previlleges: [], 
        merchantid: this.use.active.merchantid, userid: this.use.active.id, date: new Date(), muserid: null, mdate: null 
      };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }
}
