import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TellerService } from 'src/app/services/teller.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {
  @ViewChild('accountForm') public any: NgForm;
  acct: any; ne: any; edit: Boolean; res: any; id: any;
  garantors: any[] = []; accounts: any[] = []; gar: any; merchants: any[] = []; user: any;
  type: any[] = []; cust: any[] = []; users: any[] = []; locations: any[] = []; groups: any[] = [];
  constructor(private api: ApiService, private _api: ManagerService, private _nav: Router, private _route: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.formReset();
    this.getAll();
    this.getAllSetup();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchant();
    }
  }

  getAll() {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getAcct(this.id);
  }

  getAcct(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this._api.getAccount(id).subscribe((data: any) => {
        this.acct = data; this.garantors = data.garantors;
        this.ne = data.customer; this.Selected();
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Get Account Details'); });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllSetup() {
    this.api.get('accounttype?limit=100').subscribe((data: any) => {
      this.type = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch AccountType List');
    });
    this.api.get('customer?limit=100').subscribe((data: any) => {
      this.cust = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customers List');
    });
    this.api.get('officer?limit=100').subscribe((data: any) => {
      this.users = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Relation Officers List');
    });
    this.api.get('location/all').subscribe((data: any) => {
      this.locations = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Location List');
    });
    this.api.get('group?limit=200').subscribe((data: any) => {
      this.groups = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Location List');
    });
  }

  Search(event) {
    if(event.term) {
      const value = event.term;
      this.api.get('customer?limit=100&search='+value).subscribe((data: any) => {
        this.cust = data.data;
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customers List');
      });
    }
  }

  Selected() {
    this._api.getCustomerAccount(this.ne.code).subscribe((data: any) => {
      this.acct.customerid = data.customerid;
      this.ne = data; this.accounts = data.accounts;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer Previous Account');
    });
  }

  postAccount() {
    this._api.postAccount(this.acct).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Account Created', 'Added to list Successfully');
      this._nav.navigate(['/accounts/all']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Creating Fail', 'Unable to Add New Account');
    });
  }

  putAccount() {
    this.acct.muserid = this.use.active.id; this.acct.mdate = this.use.date;
    this._api.putAccount(this.acct.accountid, this.acct).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Account Updated', 'Account Details Updated Successfully');
      this._nav.navigate(['/accounts/all']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updating Fail', 'Unable to Update Account Details');
    });
  }

  AddGarantor() {
    this.garantors.push(this.gar);
    this.init();
  }

  status() {
    if (this.acct.basetype === 'Savings') {
      this.acct.status = 'ACTIVE';
    } else if (this.acct.basetype === 'Loans') {
      this.acct.status = 'PENDING';
    }
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ne = { code: null, name: '', mobile: '', location: { name: '' } };
      this.acct = {
        accountid: 0, basetype: '', type: '', code: null, number: null,
        days: 0, balance: 0, officerid: null, alert: false, customerid: null, groupid: null,
        accounttypeid: null, purpose: '', status: 'Active', active: true, garantors: this.garantors,
        merchantid: this.use.active.merchantid, userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
      this.init();
    }
  }

  init() {
    this.gar = {
      accountid: this.acct.accountid,
      fullname: '', mobile: '', gender: '', maritalstatus: '', address: '',
      userid: this.use.active.Id, date: this.use.date, muserid: null, mdate: null
    };
  }

}
