import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-country',
  templateUrl: './setup-country.component.html',
  styleUrls: ['./setup-country.component.css']
})
export class SetupCountryComponent implements OnInit {

  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; 
  country: any; countries: any[]; merchants: any[] = [];
  res: any; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private use: NavComponent, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.formReset();
    this.getAllCountries();
  }

  getAllCountries() {
    this.api.get('Country').subscribe((data: any) => {
      this.countries = data.data; this.response = data;
      this.use.showMsg('success', 'Country List', 'Fetched Country list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Country List Fail', 'Unable to Fetch Country List'); });
  }

  getMore(page,limit) {
    this.api.get('country?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.countries = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Country List Fail', 'Unable to Fetch Country List');
    });
  }

  editCountry(data) {
    this.country = data;
    this.edit = true;
  }

  delCountry(dat) {
    this.api.delete('country/'+dat.countryid).subscribe((data: any) => {
      this.res = data; this.countries.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postCountry() {
    this.api.post('country',this.country).subscribe((data: any) => {
      this.getAllCountries(); this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Country Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Country');
    });
  }

  putCountry() {
    this.country.muserid = this.use.active.id; this.country.mdate = new Date();
    this.api.put('country/'+this.country.countryid, this.country).subscribe((data: any) => {
      this.res = data; this.getAllCountries();
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Country Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.country = {
        countryid: 0, name: '', code: '', currency: '', symbol:'', merchantid: this.use.active.merchantid,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  Export() {
    this._exp.exportAsExcelFile(this.countries, 'countries');
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}







