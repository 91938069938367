import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-teller-payment',
  templateUrl: './teller-payment.component.html',
  styleUrls: ['./teller-payment.component.css']
})
export class TellerPaymentComponent implements OnInit {
  cust: any; acct: any; dp: any; wd: any; ch: any; post: Boolean;
  accounts: any[] = []; trans: any[] = []; response: any;
  constructor(private api: ApiService, private _use: NavComponent, private _api: ManagerService, private _exp: ExportExcelService) { }

  ngOnInit() {
    this.formReset();
    this.getAllAccounts();
  }

  getAllAccounts() {
    this.api.get('accounts/all').subscribe((data: any) => {
      this.accounts = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
  
  Export() {
    if(this.trans.length > 0) { this._exp.exportAsExcelFile(this.trans, this.acct.number); }
  }

  Selected(value) {
    this._api.getAccountbyNumber(value).subscribe((data: any) => {
      this.acct = data; this.cust = data.customer;
      this.trans = data.transactions;
      this.transact(this.acct.accountid); this.initDp();
      this.getCharge(data.accounttype.cotid)
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
  
  getCharge(id) {
    this.api.get('cot/'+id).subscribe((data: any) => {
      this.ch.amount = data.amount; console.log(data.amount);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Cot Amount');
    });
  }

  getAmount() {
    if (this.dp.days != null) {
      this.dp.amount = this.acct.accounttype.amount * this.dp.days;
    }
  }

  getWdAmount() {
    if (this.wd.days != null) {
      this.wd.amount = this.acct.accounttype.amount * this.wd.days;
    }
  }

  transact(value) {
    this._api.getAccountTransact(value).subscribe((data: any) => {
      this.trans = data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Transaction Fail', 'Unable to Fetch Account Transactions');
    });
  }

  Deposit() {
    this._api.Deposit(this.dp).subscribe((data: any) => {
      // console.log(data); 
      document.getElementById('deposit').click();
      this.Selected(this.acct.code);
      this._use.showMsg('success', 'Deposit Successfull', 'Amount Has been Deposited in Account');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deposit Fail', 'Unable to Perform Account Deposit.' + err.message);
    });
  }

  Withdraw() {
    this._api.Withdrawal(this.wd).subscribe((data: any) => {
      // console.log(data); 
      document.getElementById('withdraw').click();
      this.Selected(this.acct.code);
    }, (err: HttpErrorResponse) => {
       // console.log(err);
        this._use.showMsg('error', 'Withdrawal Fail', 'Unable to Perform Account Withdrawal. ' + err.message);
    });
  }

  postCharge() {
    this._api.postAccountCharge(this.ch).subscribe((data: any) => {
      document.getElementById('charge').click();
      this.Selected(this.acct.number);
      this._use.showMsg('success', 'Charge Applied', 'Account Charge Applied Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Charge Fail', 'Unable to Apply Account Charge');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.cust = { code: '', fullname: '', mobile: '', gender: '', location: '', type: '', maritalstatus: '', date: new Date() };
      this.acct = { accountid: 0, code: null, number: null, days: 0, balance: 0, alert: false,
        customerid: '', purpose: '', status: '', relationofficer: { name: 'Dedawa' },
        accounttype: { basetype: '', name: '', amount: 0, loanamount: 0 },
        userid: this._use.active.Id, date: this._use.date, muserid: null, mdate: null
      };
      this.initDp();
    }
  }

  initDp() {
    this.dp = { accountid: this.acct.accountid, number: '',
      method: 'CASH', days: 1, amount: this.acct.accounttype.amount, reference: 'DEPOSIT',
      userid: this._use.active.Id, date: this._use.date };
    this.wd = { accountid: this.acct.accountid, number: '',
      method: 'CASH', days: 0, amount: this.acct.accounttype.loanamount, reference: 'WITHDRAWAL',
      userid: this._use.active.Id, date: this._use.date
    };
    this.ch = {
      accountid: this.acct.accountid, amount: '', reference: 'Cot Charge',
      userid: this._use.active.Id, date: this._use.date
    };
  }

}
