import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-approval',
  templateUrl: './account-approval.component.html',
  styleUrls: ['./account-approval.component.css']
})
export class AccountApprovalComponent implements OnInit {
  accounts: any[] = [];
  constructor(private _use: NavComponent, private _api: ManagerService, private api: ApiService) { }

  ngOnInit() {
    this.getAllAccounts();
  }

  getAllAccounts() {
    this._api.getAccountsByStatus('Pending').subscribe((data: any) => {
      this.accounts = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
  Approve(e) {
    e.status = "Active";
    this.api.put("accounts/"+e.accountid, e).subscribe((data)=>{

    });
  }

}
