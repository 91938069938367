import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-setup-charge',
  templateUrl: './setup-charge.component.html',
  styleUrls: ['./setup-charge.component.sass']
})
export class SetupChargeComponent implements OnInit {
@ViewChild('sessionForm') public any: NgForm;
  ses: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; search: string = '';
  charges: any[] = []; merchants: any[] = []; filtered: any[]; 
  types:any[] = ["DEPOSIT", "WITHDRAWAL"]; user: any;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.curr = new Date();
    this.formReset();
    this.getAllCharge();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchant();
    }
    //this.getMerchant();
  }

  getAllCharge() {
    this.api.get('charge').subscribe((data: any) => {
      this.charges = data; this.filtered = data; this.response = data;
      this.use.showMsg('success', 'Charge List', 'Fetched Charge list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Charge List Fail', 'Unable to Fetch Charge List');
    });
  }

  getMore(page,limit) {
    this.api.get('charge?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.charges = data.data; this.filtered = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }
  // getbranch() {
  //   this.api.getCompanys().subscribe((data: any) => {
  //     this.branch = data;
  //     this.use.showMsg('success', 'Charge List', 'Fetched Charge list Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Charge List Fail', 'Unable to Fetch Charge List');
  //   });
  // }

  editCharge(data) {
    // this.set = this.charges.find(s => s.chargeid === data);
    this.ses = data;
    this.edit = true;
  }

  delCharge(dat) {
    this.api.delete('charge/'+dat.chargeid).subscribe((data: any) => {
      this.charges.slice(dat);
    }, (err: HttpErrorResponse) => { 
      this.use.showMsg('error', 'Delete Fail', 'Unable to Delete Charge'); 
    });
  }
  saveCharge() {
    if(this.ses.chargeid > 0) {
      this.putCharge();
    } else {
      this.postCharge();
    }
  }

  postCharge() {
    this.api.post('charge',this.ses).subscribe((data: any) => {
      this.getAllCharge(); this.formReset();
      this.use.showMsg('success', 'Charge Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Charge');
    });
  }

  putCharge() {
    this.ses.muserid = this.use.active.id; this.ses.mdate = new Date();
    this.api.put('charge/', this.ses).subscribe((data: any) => {
      this.getAllCharge(); this.formReset();
      this.use.showMsg('success', 'Charge Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ses = {
        chargeid: 0, type: "", percentage: true, value: 0, amount: 0, minimum: 0, maximum: 0, charge_on_custmer: false, active: true, merchantid: "", userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }

}
